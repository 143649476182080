import statusFilter from '../../../redux/Selectors/status';

function OrderFinishStatus({ order }) {

  return (
    <div className="order-finish__status">
      <h6>Status do seu pedido:</h6>{statusFilter(order.status, null).component}
    </div>
  );
}

export default OrderFinishStatus;