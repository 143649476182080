import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { filter_by_text, filter_by_category } from '../../../redux/Filters/filters.actions';

function Main({prods, prodsCategory, addToCart}) {
	const dispatch = useDispatch()

	const [search, setSearch] = useState('');
	const [products, setProducts] = useState([]);
	const [pageNumber, setPageNumber] = useState(0)

	const productsPerPage = 15;
	const pagesVisited = pageNumber * productsPerPage;
	const displayProducts = products.slice(pagesVisited, pagesVisited + productsPerPage)

	const groupByCategory = prodsCategory.reduce((group, product) => {
		let { categoria } = product;
		categoria = categoria.toLowerCase()
		group[categoria] = group[categoria] ?? [];
		group[categoria].push(product);
		return group;
	}, {});
	
	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(filter_by_text(search))
	}

	const handleChange = (e) => {
		const s = e.target.value;
		if(!s) {dispatch(filter_by_text(''))};
		setSearch(s)
	}

	const handleCategory = (category) => {
		dispatch(filter_by_text(''))
		setSearch('')
		dispatch(filter_by_category(category))
	}

	const handleSetPageNumber = (index) => {
		setPageNumber(index)
		window.scrollTo(0, 700)
	}

	useEffect(() => {
		setProducts(prods)
	}, [prods, pageNumber])
    return(
        <main role="main">
				<section className="section">
					<div className="container">
						<div className="goods-catalog">
							<div className="row">
								<div className="col-12 col-md-4 col-lg-3">
									<aside className="sidebar goods-filter">
										<span className="goods-filter-btn-close js-toggle-filter"><i className="fontello-cancel"></i></span>

										<div className="goods-filter__inner">
											
											<div className="widget widget--search">
												<form className="form--horizontal" onSubmit={(e) => handleSubmit(e)}>
													<div className="input readOnly={true}-wrp" style={{width: '55%'}}>
														<input className="textfield" name="s" type="text" placeholder="Buscar" onChange={(e) => handleChange(e)}/>
													</div>

													<button className="custom-btn custom-btn--tiny custom-btn--style-1" type="submit" role="button" >Ir</button>
												</form>
											</div>
											<div className="widget widget--categories">
												<h4 className="h6 widget-title">Categorias</h4>
												<ul className="list">
													{
														Object.keys(groupByCategory).map((categoria, index) => (
														
															<li className="list__item" key={index} onClick={() => handleCategory(categoria)}>
																<div className="list__item__link" style={{cursor: 'pointer', textTransform: 'capitalize'}}>{categoria.toLowerCase()}</div>
																<span>{groupByCategory[`${categoria}`].length}</span>
															</li>
														))
													}
													<li className="list__item" key='none' onClick={() => handleCategory('')}>
														<div className="list__item__link" style={{cursor: 'pointer'}}>Todos</div>
														<span></span>
													</li>
												</ul>
											</div>
											
											<div className="widget widget--banner">
												<a href="#"><img className="img-fluid  lazy" src="img/blank.gif" data-src="img/widget_banner_2.jpg" alt="demo" /></a>
											</div>
											
										</div>
									</aside>
								</div>

								<div className="col-12 col-md-8 col-lg-9">
									<div className="spacer py-6 d-md-none"></div>

									<div className="row align-items-center justify-content-between">
										<div className="col-auto">
											<span className="goods-filter-btn-open js-toggle-filter"><i className="fontello-filter"></i>Filter</span>
										</div>

										<div className="col-auto">
											
											<form className="ordering">
												<div className="input readOnly={true}-wrp">
													<select className="textfield wide js-select">
														<option value="1">Default Sorting</option>
														<option value="2">Price. low to high</option>
														<option value="3">Price. high to low</option>
														<option value="3">Sort by latest</option>
													</select>
												</div>
											</form>
											
										</div>
									</div>

									<div className="spacer py-3"></div>

									
									<div className="goods goods--style-1">
										<div className="__inner">
											<div className="row">
												{displayProducts?.map((p, index) => {
												    return(
													<div key={index} className="col-12 col-sm-6 col-lg-4">
														<div className="__item">
															<figure className="__image">
																<img className="" width="188" src={p.image_url} data-src={p.image_url} alt="demo" />
															</figure>

															<div className="__content">
																<h4 className="h6 __title"><a href="single_product.html">{p.produto}</a></h4>

																<div className="__category"><a href="#">{p.categoria}</a></div>

																<div className="product-price">
																	<span className="product-price__item product-price__item--new">R$ {p.preco}</span>
																</div>

																<div className="custom-btn custom-btn--medium custom-btn--style-1" onClick={() => addToCart(p)}><i className="fontello-shopping-bag"></i>Adicionar</div>
															</div>

															<span className="product-label product-label--sale">{p.unidade}</span>
														</div>
													</div>
													)
												})}
												
												
											</div>
										</div>
									</div>
									

									<div className="spacer py-5"></div>
									<nav aria-label="Page navigation example">
										<ul className="pagination justify-content-center">
											{ products?.map((product, index, array) => {
												const total = array.length / productsPerPage;
												if(index < total){
													return <li key={index} className={`page-item ${index === pageNumber ? 'active': ''}`}><a className="page-link" onClick={() => {handleSetPageNumber(index)}}>{index}</a></li>
												}
											}) }
										</ul>
									</nav>
									
								</div>
							</div>
						</div>
						

					</div>
				</section>
				

				
				<section className="section section--no-pt section--no-pb section--gutter" style={{'display':'none'}}>
					<div className="container-fluid px-md-0">
						
						<div className="simple-banner simple-banner--style-2" data-aos="fade" data-aos-offset="50">
							<div className="d-none d-lg-block">
								<img className="img-logo img-fluid  lazy" src="img/blank.gif" data-src="img/site_logo.png" alt="demo" />
							</div>

							<div className="row no-gutters">
								<div className="col-12 col-lg-6">
									<a href="#"><img className="img-fluid w-100  lazy" src="img/blank.gif" data-src="img/banner_bg_3.jpg" alt="demo" /></a>
								</div>

								<div className="col-12 col-lg-6">
									<a href="#"><img className="img-fluid w-100  lazy" src="img/blank.gif" data-src="img/banner_bg_4.jpg" alt="demo" /></a>
								</div>
							</div>
						</div>
						
					</div>
				</section>
				
			</main>
    )
}

export default Main;