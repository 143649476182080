import { database } from '../../firebase/firebase';
import { push, child, ref, set } from "firebase/database";
import { MESSAGES } from './messages.types';
import moment from 'moment';

export const add_message = (message) => {
  return {
      type: MESSAGES.ADD_MESSAGE,
      payload: message
  };
};

export const start_add_message = (message) => {
  return async (dispatch) => {
    let createdAt = moment().unix()
    const id = push(child(ref(database), 'messages')).key;
    const msg = {
      id,
      createdAt,
      type: 'home-form',
      ...message
    }
    await set(ref(database, 'messages/' + id), msg);  
    return dispatch(add_message(msg))
  }
}
