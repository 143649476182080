import { useState, useEffect } from 'react';
import './ProductsWidget.css';

function ProductsWidget({cart, handleRemove, handleCheckout}){
    const [total, setTotal] = useState()
    const [hideOrNot, setHideOrNot] = useState(false)

    useEffect(() => {
        let soma = 0;
        cart.map(({preco, qtd}) => soma += preco * qtd)
        setTotal(soma)
    }, [cart])
    return(
       <div className="products-widget">
        <ul>
            <li className="products-widget__buttons">
                    <span onClick={() => setHideOrNot(!hideOrNot)}>{hideOrNot ? 'Exibir' : 'Esconder'} / </span>
                    <span onClick={handleCheckout}>Finalizar</span>
            </li>
        </ul>
        <ul className={hideOrNot ? 'products-widget__ul hidden' : 'products-widget__ul'}>
            {cart.map((cartItem) => {
                return(
                    <li className="products-widget__li" key={cartItem.id}>
                        <div className="products-widget__container">
                            <div className="products-widget__img">
                                <img src={cartItem.image_url} />
                            </div>
                            <div className="products-widget__items">
                                <p>{cartItem.produto}</p>
                                <p>Unidades: {cartItem.qtd}</p>
                            </div>
                        </div>
                        <div 
                        onClick={() => handleRemove(cartItem.id)}
                        className="products-widget__remove"
                        >
                            <p>x</p>
                        </div>
                    </li>
                )
            })}
        </ul>
        <ul>
        <li style={{'minWidth':'250px'}}>Total: {total}</li>
        </ul>
       </div>
        
    )
}

export default ProductsWidget;