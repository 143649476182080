import BgImage from '../../../img/intro_img/12.jpeg';

function Paralax(){
    return(
            <div id="hero" className="jarallax" data-speed="0.7" data-img-position="50% 80%" style={{backgroundImage: `url(${BgImage})`, color:'#333', marginTop:'176px'}}>
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-7">
							<h1 className="__title"><span>Sítio da Felicidade</span> Produtos</h1>

							<p>
								Conheça nosso catálogo de produtos e adicione os produtos no carrinho de compras.
							</p>
						</div>
					</div>
				</div>
			</div>
    )
}

export default Paralax;