import { useDispatch } from 'react-redux';
import { set_text } from '../../redux/Filters/filters.actions';
import { start_set_products } from '../../redux/Products/products.actions';
import { Outlet } from "react-router-dom";

function ProductsRender(){
    const dispatch = useDispatch()
    dispatch(start_set_products())
    dispatch(set_text())
    return(
        <>
            <Outlet />
        </>        
    )
}

export default ProductsRender;

