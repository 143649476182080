const handleUpdate = (func, status) => {
    if(!func) return
    func(status)
}

export default (status , func) => {
    switch (status){
        case 'revision':
            return {
                component: <span className="revision">Aguardando Revisão</span>,
                componentFunction: <span onClick={() => handleUpdate(func, 'revised')} className="revision">Pedido Revisado</span>
            }
        case 'revised':
            return {
                component: <span className="revised">Aguardando Envio</span>,
                componentFunction: <span onClick={() => handleUpdate(func, 'sent')} className="revised">Pedido Enviado</span>
            }
        case 'sent':
            return {
                component: <span className="sent">Aguardando Pagamento</span>,
                componentFunction: <span onClick={() => handleUpdate(func, 'payment')} className="sent">Pagamento Recebido</span>
            }
        case 'payment':
            return {
                component: <span className="payment">Aguardando Conclusão</span>,
                componentFunction: <span onClick={() => handleUpdate(func, 'done')} className="payment">Pedido Concluído</span>
            }
        case 'done':
            return {
                component: <span className="done">Concluído</span>,
                componentFunction: <span className="done">Concluído</span>
            }
        default:
          return 'Status não encontrado'
          break;
    }
}

// aguardando revisao ok
// pedido recebido
// aguardando pagamento ok
// Pago