import {database} from '../../firebase/firebase';
import { ref, onValue, set, push, child, remove } from "firebase/database";
import { CLIENTS } from './clients.types';
import moment from 'moment';


export const set_all_clients = (clients) => {
  return {
      type: CLIENTS.SET_CLIENTS,
      payload: clients
  };
};

export const set_clients = (clients) => {
    return {
        type: CLIENTS.SET_CLIENTS,
        payload: clients
    };
};

export const add_clients = (clients) => {
  return {
      type: CLIENTS.ADD_CLIENTS,
      payload: clients
  };
};

export const add_client = (client) => {
  return {
      type: CLIENTS.ADD_CLIENT,
      payload: client
  };
};

export const update_client = (update) => {
  return {
      type: CLIENTS.UPDATE_CLIENT,
      payload: update
  };
};


export const start_add_client = (client) => {
  return async (dispatch) => {
    let createdAt = moment().unix()
    const id = push(child(ref(database), 'users')).key;
    const clientRef = {
      id,
      createdAt,
      ...client
    }
    await set(ref(database, 'users/' + id), clientRef);  
    return dispatch(add_client(clientRef))
  }
}

export const start_set_all_clients = () => {
  return async (dispatch) => {
    const clientsRef = ref(database, 'users/');
    onValue(clientsRef, (snapshot) => {
      const clients = []
      snapshot.forEach((childSnapshot) => {
        clients.push({
          id: childSnapshot.key,
          ...childSnapshot.val()
        })
      })
      return dispatch(set_all_clients(clients))
    });
  }
}

export const start_set_clients = () => {
  return async (dispatch) => {
    const clientsRef = ref(database, 'users/');
    onValue(clientsRef, (snapshot) => {
      let clients = []
      snapshot.forEach((childSnapshot) => {
        clients.push({
          id: childSnapshot.key,
          ...childSnapshot.val()
        })
      })
      clients = clients.filter((client) => client.status === 'true')
      return dispatch(set_all_clients(clients))
    });
  }
}

export const start_remove_client = (clientId) => {
  return async (dispatch) => {
    const clientRef = ref(database, 'users/' + clientId);
    remove(clientRef)  
    return
  }
}

export const start_update_client = (update) => {
  return async (dispatch) => {
    await set(ref(database, 'users/' + update.id), update);
    return dispatch(update_client(update))
  }
}

