import { FILTER } from './filters.types';

export const filter_by_id = (id) => {
    return {
        type: FILTER.BY_ID,
        payload: id
    }
}

export const filter_by_text = (text) => {
    return {
        type: FILTER.BY_TEXT,
        payload: text
    }
}

export const filter_by_category = (category) => {
    return {
        type: FILTER.BY_CATEGORY,
        payload: category
    }
}

export const set_text = (text = '') => {
    return {
        type: FILTER.SET_TEXT,
        payload: text
    }
  }