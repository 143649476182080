import { Link } from 'react-router-dom';
import Logo from '../../img/logo_gdn_new.jpeg';
import BarBg from '../../img/atualizacoes/top_bar_bg-1.jpeg'


function Header(){
    return(
            <header id="top-bar" className="top-bar top-bar--style-1">
				<div className="top-bar__bg" style={{backgroundColor: '#24292c',backgroundImage: `url(${BarBg})`,backgroundRepeat: 'no-repeat;background-position: left bottom'}}></div>

				<div className="container-fluid">
					<div className="row align-items-center justify-content-between no-gutters">

						<Link to="/" className="top-bar__logo site-logo" >
							<img className="img-fluid" src={Logo} alt="demo" />
						</Link>

						<Link to="#" id="top-bar__navigation-toggler" className="top-bar__navigation-toggler top-bar__navigation-toggler--light"><span></span></Link>

						<div id="top-bar__inner" className="top-bar__inner">
							<div>
								<nav id="top-bar__navigation" className="top-bar__navigation navigation" role="navigation">
									<ul>
										<li className="active has-submenu" style={{opacity:'0'}}>
											<Link to="/">Home</Link>

											<ul className="submenu">
												<li className="active"><Link to="index.html">Home 1</Link></li>
												<li><Link to="index_2.html">Home 2</Link></li>
												<li><Link to="index_3.html">Home 3</Link></li>
												<li><Link to="index_4.html">Home Shop</Link></li>
											</ul>
										</li>

										<li style={{opacity:'0'}}>
											<Link to="about.html">About</Link>
										</li>

										<li>
											<Link to="/sobre">Sobre</Link>
										</li>

										<li>
											<Link to="/contato">Contato</Link>
										</li>

										<li>
											<Link to="/produtos">Produtos</Link>
										</li>

										<li className="li-btn">
											<a href='https://wa.me/5519996007555?text=Pedido%20Sitio%20da%20Felicidade' target="_blank" className="custom-btn custom-btn--small custom-btn--style-4">Peça Agora</a>
										</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</header>
    )
}

export default Header;