import {useEffect} from 'react';
import ImgComponent from '../ImgComponent/ImgComponent';

function ImagesDb({storedImages, handleRemove}) {
    useEffect(() => {},[storedImages])
    return(
            <div className="images-db">
                {storedImages?.map((image) => {
                    return <ImgComponent key={image.id} url={image.imageUrl} id={image.id} name={image.imageName} handleRemove={handleRemove}/>
                })}
            </div>
    )
}

export default ImagesDb;