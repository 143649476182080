import { IMAGES } from './images.types';

const INITIAL_STATE = []

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case IMAGES.SET_IMAGES:
            return action.payload;
        case IMAGES.ADD_IMAGE:
            return state.concat(action.payload);
        case IMAGES.REMOVE_IMAGE:
            return state.filter((s) => s.id !== action.payload );
        default: return state;
    }
};

export default reducer;