import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Header from './Header/Header';
import Paralax from './Paralax/Paralax';
import Main from './Main/Main';
import ProductsWidget from './ProductsWidget/ProductsWidget';
import Footer from '../Footer/Footer';
import { start_add_checkout } from '../../redux/Checkouts/checkouts.actions';
import selectProducts from '../../redux/Selectors/products'

function Products(){
	const navigate = useNavigate();
	const dispatch = useDispatch()

	let filters = useSelector(state => state.filters);
	let prods = useSelector(state => selectProducts(state.products, filters));
	let prodsCategory = useSelector(state => state.products);

    const [cart, setCart] = useState([])
	
	const addToCart = (p) => {
		if(cart.some(cart => cart.id === p.id)){
			    let qtd;
			    let cartFilter = cart.filter((cart) => {
				if(cart.id === p.id){
					qtd = cart.qtd + 1;
				}
				return cart.id !== p.id;
			})
			setCart([
				...cartFilter,
				{
					...p,
					qtd
				}
			])
		}else{
			setCart([
				...cart,
				{
					...p,
					qtd: 1
				}	
			])
		}
	}

	const handleRemove = (id) => {
		const remove = cart.filter((cart) => cart.id !== id)
		setCart(remove)
	}

	const handleCheckout = () => {
		if(!cart) return;
		let total = 0;
		cart.map(({preco, qtd}) => {
			total += preco * qtd;
		})
		dispatch(start_add_checkout(cart, total))
			.then((res) => navigate(`checkout/${res.payload.id}`))
			.catch((err) => console.log(err))
	}

    return(
        <div className="woocommerce-page catalog-page">
            <div className="app">
					<Header cart={cart}/>
					<Paralax />
					<Main prods={prods} prodsCategory={prodsCategory} addToCart={addToCart}/>
					{ cart && 
					<ProductsWidget 
					cart={cart} 
					handleRemove={handleRemove}
					handleCheckout={handleCheckout}
					/> }
					<Footer />
            </div>
        </div>
    )
}


export default Products;
