import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { start_remove_product } from '../../../../redux/Products/products.actions';
import { Link } from 'react-router-dom'
import './Products.css'

function Products(){
    const dispatch = useDispatch()

    const productsState = useSelector(state => state.products)
    const [products, setProducts] = useState([])

    function handleDelete(prodId){
        const productsRef = products.filter(({id}) => id !== prodId)
        setProducts(productsRef)
        dispatch(start_remove_product(prodId))
    }

    useEffect(() => {
        if(productsState){
            setProducts(productsState)
        }
    },[productsState])
    return (
        <div className="products">
            <div className="btn-wrapper">
                <Link to='/admin/add'>Add +</Link>
            </div>
        <div>
            <table className="all-products">
                <thead>
                    <tr>
                        <th>Editar</th>
                        <th>Excluir</th>
                        <th>Produto</th>
                        <th>Categoria</th>
                        <th>Preço</th>
                        <th>Url</th>
                        <th>Status</th>
                        <th>Tag</th>
                        <th>Unidade</th>
                    </tr>
                </thead>
                <tbody>
            {
                products?.sort(function (a, b) {
                    if (a.produto > b.produto) {
                      return 1;
                    }
                    if (a.produto < b.produto) {
                      return -1;
                    }
                    return 0;
                  }).map((prod) => (
                  <tr key={prod.id}>
                      <td><Link to={`/admin/edit/${prod.id}`}>Edit</Link></td>
                      <td className='products-delete' onClick={(e) => {handleDelete(prod.id)}}>Del</td>
                      <td>{prod.produto}</td>
                      <td>{prod.categoria}</td>
                      <td>{prod.preco}</td>
                      <td>{prod.image_url.substring(0,20)}</td>
                      <td>{prod.status}</td>
                      <td>{prod.tag}</td>
                      <td>{prod.unidade}</td>
                  </tr>
                ))
            }
                </tbody>
            </table>
        </div>
        </div>
    )
}

export default Products;