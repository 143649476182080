import { useDispatch } from 'react-redux';
import { start_update_order_status } from '../../../../redux/Checkout/checkout.actions'
import statusFilter from '../../../../redux/Selectors/status';

function OrderControls({order, setOrderState}) {
    const dispatch = useDispatch()
    
    const handleUpdate = (status) => {
        setOrderState({
            ...order,
            status
        })
        dispatch(start_update_order_status(order.id, order, status))
    }

    return(
        <div className="order-table_controls">
            <ul>
                <li>{statusFilter(order.status, handleUpdate).componentFunction}</li>
            </ul>
        </div>
    )
}

export default OrderControls;