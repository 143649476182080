import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import OrderTableInformation from './OrderTableInformation';
import OrderTableUser from './OrderTableUser';
import OrderBag from './OrderBag';
import OrderTableBag from './OrderTableBag';
import OrderMessage from './OrderMessage';
import OrderControls from './OrderControls';
import { start_update_order } from '../../../../redux/Checkout/checkout.actions'
import { start_set_message } from '../../../../redux/Checkout/checkout.actions';
import { ExportToExcel } from './ExportToExcel';

function OrderTable({order, user}){
    const fileName = order.id;
    const dispatch = useDispatch()

    const [orderState, setOrderState] = useState(order)
    const [orderStateProds, setOrderStateProds] = useState(order.prods)
    const [bagToggle, setBagToggle] = useState(true)
    let total = 0;

    if(orderStateProds.length !== 0){
        let receita = []
        orderStateProds.filter(({removed}) => removed !== true).map(({preco, qtd}) => receita.push(preco * qtd))
        total = receita.reduce(function(total, numero){
              return total + numero;
        }, 0);
    }
    
    const handleUpdate = () => {
        if(bagToggle) return
        dispatch(start_update_order({...orderState, total}))
    }

    const setMessage = (message) => {
        dispatch(start_set_message(orderState.id, message))
    }
    
    useEffect(() => {
        if(order !== orderState){
            setOrderState(order)
        }
    },[order])
    return(
        <>
            <ExportToExcel apiData={orderStateProds} fileName={fileName} />
            <OrderControls order={orderState} setOrderState={setOrderState}/>
            <div className="order-table__header">
                <OrderTableInformation orderState={orderState} total={total}/>
                <OrderTableUser user={user}/>
            </div>
            <div className="order-table__title"><h6>Sacola de Compras:</h6><span onClick={() => {setBagToggle(!bagToggle); handleUpdate() }}>Edit</span></div>
            {!bagToggle && <OrderBag products={orderStateProds} setProducts={setOrderStateProds}/>}
            { bagToggle && <OrderTableBag products={orderStateProds}/> }
            <p className="order-table__expense">R${total}</p> 
            {orderState && <OrderMessage messages={orderState.messages} setMessage={setMessage}/>}
        </>
    )
}

export default OrderTable;