
export default (products, { text, category, sortBy, startDate, endDate}) => {
  return products?.filter((product) => {
    //const createdAtMoment = moment(product.createdAt)
    //const startDateMatch = startDate ? startDate.isSameOrBefore(createdAtMoment, 'day') : true;
    //const endDateMatch = endDate ? endDate.isSameOrAfter(createdAtMoment, 'day'): true;
    const textMatch = product.produto.toLowerCase().includes(text.toLowerCase());
    const categoryMatch = product.categoria.toLowerCase().includes(category.toLowerCase());
 
    return textMatch && categoryMatch;
  })
  /*.sort((a, b) => {
    if(sortBy === 'date'){
      return a.createdAt < b.createdAt ? 1 : -1;
    }else if (sortBy === 'amount'){
      return a.amount < b.amount ? 1 : -1;
    }
  })*/
 }