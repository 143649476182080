const filtersReducerDefaultState = {
    text: '',
    category: '',
    id: '',
    sortBy: 'date'
  };
  
  export default (state = filtersReducerDefaultState, action) => {
    switch(action.type) {
      case 'SET_TEXT':
      return {
        ...state,
        text:action.payload
      }
      case 'BY_ID':
        return {
          ...state,
          id: action.payload
        }
      case 'BY_TEXT':
        return {
          ...state,
          text: action.payload
        }
      case 'BY_CATEGORY':
        return {
          ...state,
          category: action.payload
        }
      default:
        return state;
    }
  };

  