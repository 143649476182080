import { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { start_update_client } from '../../../../redux/Clients/clients.actions';
import './EditClient.css';

function EditClient(){
    let params = useParams()
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const [client, setClient] = useState()

    const handleUpdate = (e) => {
        const name = e.target.name;
        const payload = e.target.value;

        let data = {}
        data[`${name}`] = payload
        
        setClient({
            ...client,
            ...data
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(start_update_client(client)).then(() => navigate("/admin/clients"))
    }
    
    const clients = useSelector(state => state.clients)
    
    useEffect(() => {
        const client_by_id = clients.filter(({id}) => id === params.id)
        setClient(client_by_id[0])
    },[clients])

    return (
        <div className="edit-page">
            <div>
                <div>
                <Form className="form-editpage" onChange={(e) => {handleUpdate(e)}} onSubmit={(e)=> {handleSubmit(e)}}>
                    <Form.Group>
                        <Form.Label>Nome</Form.Label>
                        <Form.Control name="nome" type="text" placeholder={client?.nome} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>CEP</Form.Label>
                        <Form.Control name="cep" type="number" placeholder={client?.cep} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Logradouro</Form.Label>
                        <Form.Control name="logradouro" type="text" placeholder={client?.logradouro} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Número</Form.Label>
                        <Form.Control name="numero" type="number" placeholder={client?.numero} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Complemento</Form.Label>
                        <Form.Control name="complemento" type="text" placeholder={client?.complemento} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Região</Form.Label>
                        <Form.Control name="regiao" type="text" placeholder={client?.regiao} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Telefone</Form.Label>
                        <Form.Control name="telefone" type="number" placeholder={client?.telefone} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Select name="pagamento">
                            <option>Selecionar método de pagamento:</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </Form.Select>
                    </Form.Group>
                    <Button type="submit">Salvar</Button>
                </Form>
                </div>
            </div>
        </div>
    )
}

export default EditClient