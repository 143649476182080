import { database } from '../../firebase/firebase';
import { push, ref, set, child, onValue, remove } from "firebase/database";
import { ADD_CHECKOUT, GET_CHECKOUTS, CLOSE_ORDER, REMOVE_ORDER, REMOVE_CHECKOUT, RESET_CHECKOUTS } from './checkouts.types';
import moment from 'moment';

export const add_checkout = (checkout) => {
  return {
      type: ADD_CHECKOUT,
      payload: checkout
  };
};

export const remove_order = (order) => {
  return {
      type: REMOVE_ORDER,
      payload: order
  };
};

export const remove_checkout = (checkoutId) => {
  return {
      type: REMOVE_CHECKOUT,
      payload: checkoutId
  };
};

export const get_checkouts = (checkouts) => {
  return {
      type: GET_CHECKOUTS,
      payload: checkouts
  };
};

export const close_order = (closeOrder) => {
  return {
      type: CLOSE_ORDER,
      payload: closeOrder
  };
};

export const reset_checkouts = () => {
  return {
      type: RESET_CHECKOUTS,
      payload: []
  };
};

export const start_get_checkouts = () => {
  return (dispatch) => {
    const checkout = ref(database, 'checkouts/');
    onValue(checkout, (snapshot) => {
      let checkouts = []
      snapshot.forEach((childSnapshot) => {
        checkouts.push({
          id: childSnapshot.key,
          ...childSnapshot.val()
        })
      })
      return dispatch(get_checkouts(checkouts))
    });
  }
}


export const start_add_checkout = (cart, total) => {
  return async (dispatch) => {
    let now = moment().unix()
    const newPostKey = push(child(ref(database), 'checkouts')).key;
    await set(ref(database, 'checkouts/' + newPostKey), {
      prods: cart,
      status: 'cart',
      userId: '',
      total,
      createdAt: now
    });  
    return dispatch(add_checkout({
      id: newPostKey,
      prods: cart,
      status: 'cart',
      userId: '',
      total,
      createdAt: now
    }))
  }
}

export const start_close_order = (closeOrder) => {
  return (dispatch) => {
    set(ref(database, 'checkouts/' + closeOrder.id), closeOrder);
    return dispatch(close_order(closeOrder))
  }
}

export const start_remove_order = (checkoutId, index, order) => {
  return (dispatch) => {
    set(ref(database, `checkouts/${checkoutId}/prods/${index}`), {
      ...order,
      removed: 'true'
    });
    return dispatch(remove_order({checkoutId, index, order: {...order, removed: true}}))
  }
}

export const start_remove_checkout = (checkoutId) => {
  return (dispatch) => {
    remove(ref(database, `checkouts/${checkoutId}`));
    return dispatch(remove_checkout({checkoutId}))
  }
}
