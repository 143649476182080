import { Link } from 'react-router-dom';
import Logo from '../../../img/logo_gdn_new.jpeg';
import BarBg from '../../../img/top_bar_bg-2.png'

function Header({cart}){
    return(
            <header id="top-bar" className="top-bar top-bar--style-2">
            <div className="top-bar__bg" style={{backgroundColor: '#24292c',backgroundImage: `url(${BarBg})`,backgroundRepeat: 'no-repeat;background-position: left bottom'}}></div>

            <div className="container position-relative">
                <div className="row justify-content-between no-gutters">

                    <Link className="top-bar__logo site-logo" to="/">
                        <img className="img-fluid" src={Logo} alt="demo" />
                    </Link>

                    <a id="top-bar__navigation-toggler" className="top-bar__navigation-toggler top-bar__navigation-toggler--dark" href=""><span></span></a>

                    <div id="top-bar__inner" className="top-bar__inner  text-lg-right">
                        <div>
                            <div className="d-lg-flex flex-lg-column-reverse align-items-lg-end">
                                <nav id="top-bar__navigation" className="top-bar__navigation navigation" role="navigation">
                                    <ul>
                                        <li>
                                            <Link to="/">Home</Link>
                                        </li>

                                        <li>
                                            <Link to="/sobre">Sobre</Link>
                                        </li>

                                        <li>
                                            <Link to="/contato">Contato</Link>  
                                        </li>

                                        <li className="li-profile">
                                            <Link to="/userAdmin"><i className="fontello-profile"></i></Link>
                                        </li>

                                        <li className="li-cart">
                                            <Link to={'shoppingcart'}><i className="fontello-shopping-bag"></i><span className="total">{cart?.length}</span></Link>
                                        </li>

                                        <li className="li-btn">
                                        <a href='https://wa.me/5519996007555?text=Pedido%20Sitio%20da%20Felicidade' target="_blank" className="custom-btn custom-btn--small custom-btn--style-4">Peça Agora</a>
                                        </li>
                                    </ul>
                                </nav>

                                <div className="top-bar__contacts">
                                    <span>Sítio da Felicidade, Cosmópolis, São Paulo - SP</span>
                                    <span><a href="#">Cel | Whatsapp | +55 19 99600 7555</a></span>
                                    <span><a href="mailto:sdafelicidade@gmail.com">sdafelicidade@gmail.com</a></span>

                                    <div className="social-btns">
                                        <a style={{opacity:'0'}} className="fontello-twitter" href="#"></a>
                                        <a style={{opacity:'0'}} className="fontello-facebook" href="#"></a>
                                        <a href="https://www.instagram.com/sitio.da.felicidade/"><i className="fontello-linkedin-squared"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </header>
    )
}

export default Header;