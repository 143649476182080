import { AuthProvider } from '../Contexts/AuthContext';
import Header from './Header/Header';
import { useDispatch } from 'react-redux';
import { start_set_all_products } from '../../redux/Products/products.actions';
import { start_set_all_clients } from '../../redux/Clients/clients.actions';
import { start_set_images } from '../../redux/Images/images.actions';
import { start_set_users } from '../../redux/Users/users.actions';
import { start_get_checkouts } from '../../redux/Checkouts/checkouts.actions';
import { Outlet } from "react-router-dom";
import './Admin.css';

function Admin(){
    const dispatch = useDispatch()
    dispatch(start_set_images())
    dispatch(start_set_all_products())
    dispatch(start_set_all_clients())
    dispatch(start_get_checkouts())
    dispatch(start_set_users())
    return(
        <div className="admin">
            <AuthProvider>
                <Header />
                <main className="main-admin">
                    <Outlet />
                </main>
            </AuthProvider>
        </div>        
    )
}

export default Admin;