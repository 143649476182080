import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { start_remove_client } from '../../../../redux/Clients/clients.actions';
import { Link } from 'react-router-dom'
import './Clients.css'

function Clients(){
    const dispatch = useDispatch()

    const clientsState = useSelector(state => state.clients)
    const [clients, setClients] = useState([])

    function handleDelete(clientId){
        const clientsRef = clients.filter(({id}) => id !== clientId)
        setClients(clientsRef)
        dispatch(start_remove_client(clientId))
    }

    useEffect(() => {
        if(clientsState){
            setClients(clientsState)
        }
    },[clientsState])
    return (
        <div className="clients">
            <div className="btn-wrapper">
                <Link to='/admin/addClient'>Add +</Link>
            </div>
        <div>
            <table className="all-clients">
                <thead>
                    <tr>
                        <th>Editar</th>
                        <th>Nome</th>
                        <th>CEP</th>
                        <th>Logradouro</th>
                        <th>Número</th>
                        <th>Complemento</th>
                        <th>Região</th>
                        <th>Telefone</th>
                        <th>Pagamento</th>
                    </tr>
                </thead>
                <tbody>
            {
                clients?.sort(function (a, b) {
                    if (a.nome > b.nome) {
                      return 1;
                    }
                    if (a.nome < b.nome) {
                      return -1;
                    }
                    return 0;
                  }).map((cli) => (
                  <tr key={cli.id}>
                      <td><Link to={`/admin/editClient/${cli.id}`}>Edit</Link></td>
                      <td>{cli.nome}</td>
                      <td>{cli.cep}</td>
                      <td>{cli.logradouro}</td>
                      <td>{cli.numero}</td>
                      <td>{cli.complemento}</td>
                      <td>{cli.regiao}</td>
                      <td>{cli.telefone}</td>
                      <td>{cli.pagamento}</td>
                  </tr>
                ))
            }
                </tbody>
            </table>
        </div>
        </div>
    )
}

export default Clients;