 export function crop(file, aspectRatio) {
    // we return a Promise that gets resolved with our canvas element
    return new Promise((resolve) => {
        const fileName = file.name;
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (event) => {
            const inputImage = document.createElement('img');
            inputImage.src = event.target.result;
        
            // we want to wait for our image to load
            inputImage.onload = () => {
                
                // let's store the width and height of our image
                let inputWidth = inputImage.naturalWidth;
                let inputHeight = inputImage.naturalHeight;
        
                // get the aspect ratio of the input image
                const inputImageAspectRatio = inputWidth / inputHeight;

                // reduce the image sizes
                let valueToReduceW = 0;
                let valueToReduceH = 0;
                if(inputWidth > 250 && inputHeight > 250){
                    valueToReduceW = inputWidth - 250;
                    valueToReduceH = (inputHeight*valueToReduceW) / inputWidth;
                    inputWidth = inputWidth - valueToReduceW;
                    inputHeight = inputHeight - valueToReduceH;
                }
        
                // if it's bigger than our target aspect ratio
                let outputWidth = inputWidth;
                let outputHeight = inputHeight;
                
                if (inputImageAspectRatio > aspectRatio) {
                outputWidth = inputHeight * aspectRatio;
                } else if (inputImageAspectRatio < aspectRatio) {
                outputHeight = inputWidth / aspectRatio;
                }
        
                // calculate the position to draw the image at
                const outputX = (outputWidth - inputWidth) * 0.5;
                const outputY = (outputHeight - inputHeight) * 0.5;
        
                // create a canvas that will present the output image
                const outputImage = document.createElement("canvas");
        
                // set it to the same size as the image
                outputImage.width = outputWidth;
                outputImage.height = outputHeight;
        
                /*ctx.drawImage(image,
                    10, 10,   // Start at 10 pixels from the left and the top of the image (crop),
                    80, 30,   // "Get" a `80 * 30` (w * h) area from the source image (crop),
                    0, 0,     // Place the result at 0, 0 in the canvas,
                    160, 60); // With as width / height: 160 * 60 (scale)*/

                // draw our image at position 0, 0 on the canvas
                const ctx = outputImage.getContext("2d");
                ctx.drawImage(inputImage, outputX, outputY, inputImage.naturalWidth, inputImage.naturalHeight, 0, 0, 250, 250);

                outputImage.toBlob(blob => {
                    if (!blob) {
                        //reject(new Error('Canvas is empty'));
                        console.error("Canvas is empty");
                        return;
                    }
                    blob.name = fileName;
                    resolve(blob);
                   
                }, "image/jpeg");
            };
            // start loading our image
            inputImage.src = event.target.result;
        }
    
    });
  }