import { useEffect } from 'react';
import Header from './components/Header/Header';
import StartScreen from './components/Home/StartScreen/StartScreen';
import Main from './components/Home/Main/Main';
import Footer from './components/Footer/Footer';
import { Helmet, HelmetProvider } from 'react-helmet-async';


function App() {
  useEffect(() => {

  },[])
  return (
    <div className="app">
      <HelmetProvider>
        <Header />
        <StartScreen />
        <Main />
        <Footer />
          <Helmet>
          <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js" integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
          <script src="https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.min.js" integrity="sha512-uURl+ZXMBrF4AwGaWmEetzrd+J5/8NRkWAvJx5sbPSSuOb0bZLqf+tOzniObO00BjHa/dD7gub9oCGMLPQHtQA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
          <script type="text/javascript" src="../../js/main.min.js"></script>
          <script type="text/javascript" src="../../js/lazyLoads.js"></script>
        </Helmet> 
      </HelmetProvider>
    </div>
  );
}

export default App;
