import { MESSAGES } from './messages.types';

const INITIAL_STATE = []

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MESSAGES.GET_MESSAGES:
            return action.payload;
        case MESSAGES.ADD_MESSAGE:
            return [...state, action.payload];
        default: return state;
    }
};

export default reducer;