import { useState, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from '@firebase/storage';
import { storage } from '../../../../firebase/firebase';
import { start_add_image, start_remove_image } from '../../../../redux/Images/images.actions'
import { useDispatch, useSelector } from 'react-redux';
import { crop } from './CropImage';

import ImagesDb from './ImagesDb/ImagesDb';
import uuid from 'react-uuid';
import './UploadImages.css'

function UploadImages(){
    const storedImages = useSelector(state => state.images)
    const dispatch = useDispatch()
    
    const [loading, setLoading] = useState(true);
    const [images, setImages] = useState()
    const [progress, setProgress] = useState([])
    const [loadingImages, setLoadingImages] = useState('')
    
    const handleRemove = (id, name) => {
        if(!id) return
        deleteImage(id, name)
    }

    const handleChange = (e) => {
        const promises = []
            for(let i=0; i < e.target.files.length; i++){
                const newImage = e.target.files[i]
                const promiseReturn = crop(newImage, 250 / 250)
                promises.push(promiseReturn)
                setLoadingImages(i+1)
            }
        Promise.all(promises)
            .then((result) => {
                setImages(result)
                setLoading(false)
                console.log('all crops completed', result)
            })
            .catch((error) => { console.log(error)})
        }

    const handleSubmit = (e) => {
        e.target[1].value = null;
        e.preventDefault();
        if(!images) return
        uploadFiles();
        setLoadingImages('')
    }

    const deleteImage = (id, imageName) => {
        // Create a reference to the file to delete
        const desertRef = ref(storage, `produtos/${imageName}`);

        // Delete the file
        deleteObject(desertRef).then(() => {
        // File deleted successfully
            dispatch(start_remove_image(id))
        }).catch((error) => {
        // Uh-oh, an error occurred!
        });
    }

    const uploadFiles = () => {
        const promises = [];
        const prog = [];
        images.map((image, index) => {
            let imageId = uuid()
            let imageName = `${image.name.replace(/[^a-zA-Z0-9]/g, '')}_${imageId}`;

            const storageRef = ref(storage, `/produtos/${imageName}`) 
            const uploadTask = uploadBytesResumable(storageRef, image)

            promises.push(uploadTask)

            uploadTask.on('state_changed', (snapshot) => {
                    prog[index] = Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setProgress(prog)
                }, 
                (err) => console.log(err),
                async () => {
                    await getDownloadURL(uploadTask.snapshot.ref)
                    .then(url => {
                        
                        dispatch(start_add_image({
                            id: imageId,
                            imageUrl: url,
                            imageName: imageName
                        }))
                    })
                }    
            )
        })
        Promise.all(promises)
            .then(() => {console.log('all images upload'); setImages([])})
            .catch((error) => {console.log(error); return}) 
    }
    return(
        <div className="container-upload">
            <div className="container-upload__form">
                <form className="form-upload" onSubmit={handleSubmit}>
                    <label htmlFor="arquivo">Selecionar arquivo(s)</label>
                    <input type="file" multiple onChange={handleChange} name="arquivo" id="arquivo"></input>
                    <button disabled={loading} className="btn-enviar">Upload</button>
                </form> 
            { loadingImages && <div className="loading-images">{loadingImages}</div>}
            </div>
            <div className="progress-bars">
                {progress.map((prog, index) => {
                    return(
                        <div key={index}>
                            <p data-value={prog}></p>
                            <progress max="100" value={prog} className="html5">
                            <div className="progress-bar">
                                <span>{prog}%</span>
                            </div>
                            </progress>
                        </div>
                    )
                })
            }
            </div>
            <ImagesDb storedImages={storedImages} handleRemove={handleRemove} />
        </div>
    )
}

export default UploadImages;