import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { start_update_order_with_user, start_get_checkout_by_id, start_update_products } from '../../../redux/Checkout/checkout.actions';
import NumberFormat from 'react-number-format';
import './Entry.css';

function CheckOrder({checkout}){
  const dispatch = useDispatch();

  const [products, setProducts] = useState([])

  const handleChange = (e, index) => {
    if(e.target.value < 0) return;
    let cloneProduct = [...products];

    let productChange = {
        ...products[index],
        qtd: e.target.value
    }

    cloneProduct[index] = productChange;
    setProducts(cloneProduct)
    console.log(checkout.id, cloneProduct)
    dispatch(start_update_products(checkout.id, cloneProduct))
}

const handleRemove = (id) => {
    const newProduct = products.filter((prod) => prod.id !== id)
    setProducts(newProduct)
    dispatch(start_update_products(checkout.id, newProduct))
}

  useEffect(() => {     
      setProducts(checkout.prods)
  }, [checkout])
  return(
    <div>
      <h6>Confira a sua sacola de compras:</h6>
      <div className="checkout__table">
        <table>
          <tbody>
            {products?.map((product, index) => (
              <tr key={product.id} className="checkout__table_tr">
                <td>
                  <div className="d-table">
                    <div className="d-table-cell align-middle">
                      <figure className="__image">
                        <a href="#">
                          <img
                            className=""
                            src={product.image_url}
                            data-src={product.image_url}
                            alt={product.produto}
                          />
                        </a>
                      </figure>
                    </div>

                    <div className="d-table-cell align-middle">
                      <a href="#" className="__name">
                        {product.produto}
                      </a>
                    </div>
                  </div>
                </td>

                <td>
                  <span className="__amount">x <input onChange={(e) => handleChange(e, index)} className="input-qtd" type="number" placeholder={product.qtd} value={product.qtd}/></span>
                </td>

                <td>
                  <span className="__total">
                    R$
                    {product.preco * product.qtd}
                  </span>
                </td>

                <td onClick={() => {handleRemove(product.id)}}>
                  <span className="__remove">
                    x
                  </span>
                </td>
              </tr>
            )).sort()}
          </tbody>
          <span>Taxa de envio: R$ 16.5</span>
        </table>
      </div>
    </div>
  )
}

function Entry(){
  function isEmptyObject(value) {
    return Object.keys(value).length === 0 && value.constructor === Object;
  }

  function isEmptyArray(value) {
    return value.length === 0;
  }
  
  let params = useParams();
  let navigate = useNavigate();

  const [phone, setPhone] = useState('');

  const dispatch = useDispatch();
  const checkout = useSelector((state) => state.checkout);
  const users = useSelector((state) => state.users);

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!phone) return;
    dispatch(start_update_order_with_user(phone, checkout))
    navigate(`/produtos/checkout/orderfinish/${params.id}`)
  }

  const handleChange = ({value}) => { setPhone(value) };

  useEffect(() => {
    dispatch(start_get_checkout_by_id(params.id))
  }, [])
  return(
    <div className="client-order">
      <div>
        { !isEmptyObject(checkout) && <CheckOrder checkout={checkout}/> }
        { isEmptyArray(users) && 
        <div>
          <h6>Insira o número do seu Celular:</h6>
          <form className='client-order__form' onSubmit={ (e) => handleSubmit(e) }>
            <NumberFormat value={null} format='+55 (##) #####-####' onValueChange={(values, sourceInfo) => handleChange(values)}/>
            <button type="submit" className="custom-btn custom-btn--medium custom-btn--style-1" style={{marginTop: '10px'}}>
              Enviar
            </button>
          </form>
        </div> }
      </div>
    </div>
  )
}

export default Entry;
