function OrderFinishBag({ order }) {

    let total = 0;
    let receita = []
    order.prods.map(({preco, qtd}) => receita.push(preco * qtd))
    total = receita.reduce(function(total, numero){
        return total + numero;
    }, 0);

  return (
    <>
    <div>
      <h6>Sua Sacola</h6>
    </div>
    <div className="checkout__table">
      <table>
        <tbody>
          {order?.prods?.map((product) => (
            <tr key={product.id}>
              <td width="65%">
                <div className="d-table">
                  <div className="d-table-cell align-middle">
                    <figure className="__image">
                      <a href="#">
                        <img
                          className=""
                          src={product.image_url}
                          data-src={product.image_url}
                          alt={product.produto}
                        />
                      </a>
                    </figure>
                  </div>
                  <div className="d-table-cell align-middle">
                    <a href="#" className="__name">
                      {product.produto}
                    </a>
                  </div>
                </div>
              </td>

              <td>
                <span className="__amount">x {product.qtd}</span>
              </td>

              <td width="1%">
                <span className="__total">
                  R$
                  {product.preco * product.qtd}
                </span>
              </td>
            </tr>
          )).sort()}
        </tbody>
      </table>
    </div>
    <div className="order-finish--total_frete">
      <span>Total R$ {total}</span>
      <span>Frete R$ 16.5</span>
      <span>Total + Frete R$ {total + 16.5}</span>
    </div>
    </>
  );
}

export default OrderFinishBag;