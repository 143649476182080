import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { start_get_checkout_by_id, start_set_message } from '../../../redux/Checkout/checkout.actions';
import { useEffect } from 'react';
import OrderFinishBag from './OrderFinishBag';
import OrderFinishStatus from './OrderFinishStatus';
import OrderFinishMessage from './OrderFinishMessage';

import './OrderFinish.css'

function OrderFinish(){
    const dispatch = useDispatch();
    const params = useParams();
    const orderFinish = useSelector(state => state.checkout);

    const setMessage = (message) => {
        dispatch(start_set_message(params.id, message))
    }

    useEffect(() => {
        if(orderFinish.id !== params.id){
            dispatch(start_get_checkout_by_id(params.id))
        }
    },[orderFinish])
    return(
        <div className="order-finish">
            {Object.keys(orderFinish).length !== 0 && <OrderFinishBag order={orderFinish}/>}
            {Object.keys(orderFinish).length !== 0 && <OrderFinishStatus order={orderFinish}/>}
            {Object.keys(orderFinish).length !== 0 && orderFinish && <OrderFinishMessage messages={orderFinish.messages} setMessage={setMessage}/>}
        </div>
    )
}

export default OrderFinish;