import { useState, useEffect } from 'react';
import moment from 'moment';

function OrderFinishMessage({messages, setMessage}) {
    const [msg, setMsg] = useState('');
    const [msgs, setMsgs] = useState([]);

    const handleChange = (e) => {
        const msgInput = e.target.value;
        setMsg(msgInput)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(!msg) return;
        let msgsClone = msgs || [];
        msgsClone.push({
            sender: 'comprador',
            content: msg,
            createdAt: moment().unix()
        })
        setMsgs(msgsClone)
        setMessage(msgsClone)
        setMsg('')
    }

    useEffect(() => {
        if(messages !== msgs){
            setMsgs(messages)
        }
    },[messages])

    return(
        <div>
            <div className="order-finish__messages">
                {msgs?.map((message, index) => (
                    <div key={index} className={`order-finish__message ${message.sender === 'comprador' ? 'left-msg' : 'right-msg'}`}>
                        <div>
                            <span>{message.content}</span>
                            <span className="message-date">{moment.unix(message?.createdAt).format("DD/MM/YYYY")}</span>
                        </div>
                    </div>
                )).reverse()}
            </div>
            <div className="order-finish__input-message">
                <form onSubmit={(e)=> handleSubmit(e)}>
                    <textarea value={msg} name="message" type="text" placeholder="Escreva aqui sua mensagem" onChange={(e) => handleChange(e)}/>
                    <button type="submit">Enviar Mensagem</button>
                </form>
            </div>
        </div>
    )
}

export default OrderFinishMessage;