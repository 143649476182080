import { Link } from 'react-router-dom';
import ImageHome from '../../../img/atualizacoes/fotoHome.jpeg'

function StartScreen(){
    return(
        <div id="start-screen" className="start-screen start-screen--style-1" data-scroll-discover="true">
				
				<div id="start-screen__bg" class="start-screen__bg">
					<div id="vegas-slider" data-dots="true" style={{height: '796px'}} class="vegas-container">
						<div class="vegas-slide vegas-transition-fade vegas-transition-fade-in" style={{transition: 'all 4000ms ease 0s'}}>
							<div class="vegas-slide-inner" style={{backgroundImage: `url(${ImageHome})`, backgroundColor: 'rgba(0, 0, 0, 0)', backgroundPosition: 'center center', backgroundSize: 'cover'}}>
							</div>
						</div>
						<div class="vegas-overlay" style={{backgroundImage: 'url(../img/home_img/overlays/05.png)'}}></div>
						<div class="vegas-wrapper" style={{overflow: 'visible', padding: '0px'}}>
							<div class="vegas-control">
								<span id="vegas-control__prev" class="vegas-control__btn">Prev</span>
								<span id="vegas-control__next" class="vegas-control__btn">Next</span>
								<nav class="vegas-dots" style={{opacity:'0'}}>
									
								</nav>
							</div>
						</div>
					</div>
				</div>
				

				<div style={{opacity: '1'}} id="start-screen__content-container" className="start-screen__content-container  text-white">
					<div style={{opacity: '1', visibility: 'visible'}} className="start-screen__content__item start-screen__content__item--1  align-items-center">
						<div  className="container">
							<div  className="row justify-content-center">
								<div className="col-12 col-md-10 col-xl-8">
									<div className="__name">Orgânicos</div>

									<h2 className="__title text-white">Sítio da Felicidade</h2>

									<p className="text-center">
										Alimentos produzidos com manejo orgânico, em harmonia com a terra e os seres.
									</p>

									<p className="text-center mt-5 mt-md-10">
										<span className="d-none d-sm-block"><Link className="custom-btn custom-btn--big custom-btn--style-3" to="#">Descubra</Link></span>

										<span className="d-block d-sm-none"><Link className="custom-btn custom-btn--small custom-btn--style-3" to="#">Descubra</Link></span>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<span className="scroll-discover"></span>
			</div>
    )
}

export default StartScreen;